import { FoxBizRequest, FoxRestBizClient } from "@/api/client";

/**
 * 메뉴관리 화면 관련 기능 클래스
 */
class MenuManage {

    /**
     * 생성자
     */
    constructor() {
    }

    /**
     * 사용자별 권한 메뉴 목록 조회
     * @param {String} username 
     */
    getUserByAuthMenuList(username) {

        return new Promise((resolve, reject) => {

            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url)
            const request = new FoxBizRequest()
            request.classId = 'Site.Admin.Biz.MenuBiz'
            request.methodId = 'GetUserByAuthMenuList'
            request.parameters.userID = username

            service.execute(request).then(response => {

                const resultData = response.data.result.value.Table.rows;
                resolve(resultData);
            })
            .catch(error => reject(error))
        })
    }

    /**
     * 메뉴관리 조회
     * @returns 
     */
    getMenuInfo() {
        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.MenuBiz';
            request.methodId = 'GetMenuInfo';

            service.execute(request).then(response => {

                const rows = response.data.result.value.Table.rows;

                if (!!rows.length) resolve(rows);
                else resolve(null);
            }).catch(error => reject(error));
        });
    }

    /**
     * 메뉴관리 정보 등록
     * @param {Object}  
     * @returns 
     */
    saveMenuInfo(menuInfo) {
        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.MenuBiz';
            request.methodId = 'CreateMenuInfo';
            request.parameters = menuInfo

            service.execute(request).then(response => {

                resolve(response)

            }).catch(error => reject(error));
        });
    }

    /**
     * 배너 정보 수정
     * @param {Object} menuInfo
     * @returns 
     */
    updateMenuInfo(menuInfo) {
        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.MenuBiz';
            request.methodId = 'UpdateMenuInfo';
            request.parameters = menuInfo

            service.execute(request).then(response => {

                resolve(response)

            }).catch(error => reject(error));
        });
    }

    /**
     * 배너 정보 삭제
     * @param {Object} menuInfo
     * @returns 
     */
    deleteMenuInfo(menuInfo) {
        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.MenuBiz';
            request.methodId = 'DeleteMenuInfo';
            request.parameters = menuInfo

            service.execute(request).then(response => {

                resolve(response)
                
            }).catch(error => reject(error));
        });
    }
}

export const menuManage = new MenuManage();