<template>
    <div :class="[
        { wrap: true },
        { 'header-hide': !state.menu.isLeftMenuPanelActive },
    ]">
        <header id="header">
            <h1><span>한국토지신탁</span><br>관리자 페이지</h1>
            <nav id="gnb">
                <ul>
                    <li v-for="leftMenu in state.menu.leftMenuItems" v-bind:key="leftMenu" :class="[
                        leftMenu.IconClass,
                        { active: leftMenu.isActive },
                        { depth2: !!leftMenu.children && leftMenu.children.length }
                    ]">

                        <router-link v-if="!!leftMenu.ProgramPath" :to="leftMenu.ProgramPath"
                            @click="eventHandler.menu.onLeftMenuClick($event, leftMenu)">{{ leftMenu.MenuName }}
                        </router-link>
                        <a v-else href="#" :title="leftMenu.MenuName"
                            @click="eventHandler.menu.onLeftMenuClick($event, leftMenu)">{{ leftMenu.MenuName }}</a>

                        <SlideUpDown v-model="leftMenu.isActive" :duration="300">
                            <ul>
                                <li v-for="childMenu in leftMenu.children" v-bind:key="childMenu"
                                    :class="[childMenu.IconClass, { active: childMenu.isActive }]">

                                    <router-link v-if="!!childMenu.ProgramPath" :to="childMenu.ProgramPath"
                                        :class="{ active: childMenu.isActive }"
                                        @click="eventHandler.menu.onLeftMenuClick($event, childMenu)">
                                        {{ childMenu.MenuName }}</router-link>
                                    <a v-else href="#" :title="childMenu.MenuName"
                                        @click="eventHandler.menu.onLeftMenuClick($event, childMenu)">{{
                                                childMenu.MenuName
                                        }}</a>
                                </li>
                            </ul>
                        </SlideUpDown>
                    </li>
                </ul>
            </nav>
        </header>
        <div class="header-top">
            <div>
                <button type="button" title="메뉴" class="header-btn"
                    @click="state.menu.isLeftMenuPanelActive = !state.menu.isLeftMenuPanelActive">
                    <span class="text_hidden">메뉴</span>
                </button>
                <ul class="top-menu">
                    <li v-for="topMenu in state.menu.topMenuItems" v-bind:key="topMenu"
                        :class="{ on: topMenu.isActive }">
                        <a href="#" :title="topMenu.MenuName" @click="eventHandler.menu.onTopMenuClick(topMenu)">{{
                                topMenu.MenuName
                        }}</a>

                    </li>
                </ul>
            </div>
            <div :class="['h-util', { active: state.isUserContextMenuOpen }]">
                <button type="button"
                    @click="{ state.isUserContextMenuOpen = state.isUserContextMenuOpen ? false : true; }">
                    <span>{{ store.getters.getUserName }}</span>
                </button>
                <ul>
                    <li><a href="#" title="Logout" @click="methods.logout">Logout</a></li>
                </ul>
            </div>
        </div>
        <div class="admin-container" style="min-height: calc(100vh - 118px);">

            <!-- 히스토리 -->
            <div class="breadcrumb-wrap">
                <ul>
                    <li class="home" @click="methods.goHome"><span class="text_hidden">home</span></li>
                    <li v-if="!!state.menu.selectedTopMenuName">{{ state.menu.selectedTopMenuName }}</li>
                    <li v-if="!!state.menu.selectedLeftMenuName">{{ state.menu.selectedLeftMenuName }}</li>
                    <li v-if="!!state.menu.selectedLeftChildMenuName">{{ state.menu.selectedLeftChildMenuName }}</li>
                </ul>
            </div>
            <!-- //히스토리 -->

            <!-- 컨텐츠 -->
            <router-view :key="$route.fullPath" />
            
        </div>
        <footer id="footer">
            <p>COPYRIGHT© KOREIT.CO.KR ALL RIGHT RESERVED</p>
        </footer>
        <div class="progress-popup" style="display:none;" v-slow="store.getters.isLoading">
            <div class="progress-inner check">
                <div>
                    <p>처리중입니다</p>
                    <span>잠시만 기다려 주세요</span>
                    <ProgressBar mode="indeterminate"></ProgressBar>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { reactive, ref } from '@vue/reactivity';
import { useStore } from 'vuex';
import { onMounted, onUpdated } from '@vue/runtime-core';
import { useRouter } from 'vue-router';
import { menuManage } from '@/modules/system/PCM_SM0200E.js';
import alertConfirmManager from '@/utils/alert-confirm-manager';
import SlideUpDown from 'vue3-slide-up-down'

export default {
    components: { SlideUpDown },
    setup() {

        // 스토어
        const store = useStore();

        // 라우터
        const router = useRouter();

        // state (변수)
        const state = reactive({

            // 사용자 컨텍스트 메뉴 오픈여부
            isUserContextMenuOpen: false,

            // 메뉴 관련
            menu: {

                // 좌측메뉴 패널 활성여부
                isLeftMenuPanelActive: true,

                // 선택한 top 메뉴명
                selectedTopMenuName: null,

                // 선택한 좌측 메뉴명
                selectedLeftMenuName: null,

                // 선택한 좌측 자식 메뉴명
                selectedLeftChildMenuName: null,

                // 전체 메뉴 목록
                allMenuItems: [],

                // top 메뉴 목록
                topMenuItems: null,

                // 좌측메뉴 목록
                leftMenuItems: null,
            },
        });

        // 이벤트 핸들러
        const eventHandler = {

            // 메뉴 관련 이벤트 핸들러
            menu: {

                /**
                 * top 메뉴 클릭 이벤트 처리
                 * @param {*} topMenuInfo
                 */
                onTopMenuClick: topMenuInfo => {

                    // 선택한 최상위 메뉴ID
                    const topMenuId = topMenuInfo.MenuID;

                    // 선택한 최상위 메뉴ID 설정 (session storage)
                    sessionStorage.setItem('selectedTopMenuId', topMenuId);

                    // 선택한 좌측 메뉴ID 삭제 (session storage)
                    sessionStorage.removeItem('selectedLeftMenuId');

                    // 선택한 좌측 자식메뉴ID 삭제 (session storage)
                    sessionStorage.removeItem('selectedLeftChildMenuId');

                    // 최상위 메뉴 활성여부 설정
                    state.menu.topMenuItems.forEach(item => {

                        if (item.MenuID === topMenuId) item.isActive = true;
                        else item.isActive = false;
                    });

                    // 선택한 최상위 메뉴명 설정
                    state.menu.selectedTopMenuName = topMenuInfo.MenuName;

                    // 선택한 좌측메뉴명 초기화
                    state.menu.selectedLeftMenuName = null;

                    // 선택한 좌측 자식메뉴명 초기화
                    state.menu.selectedLeftChildMenuName = null;

                    //#region  선택한 최상위 메뉴의 자식 메뉴 설정
                    state.menu.leftMenuItems = [];
                    topMenuInfo.children.forEach(item => {

                        item.isActive = false;
                        state.menu.leftMenuItems.push(item);
                    });
                    //#endregion
                },

                /**
                 * 좌측 메뉴 클릭 이벤트 처리
                 * @param {*} menuInfo : 메뉴 정보
                 */
                onLeftMenuClick: (event, menuInfo) => {

                    const menuId = menuInfo.MenuID;
                    const menuLevel = menuInfo.MenuLevel;
                    const isActive = menuInfo.isActive;

                    switch (menuLevel) {
                        case 1: {

                            // 선택한 좌측 메뉴명 설정
                            state.menu.selectedLeftMenuName = menuInfo.MenuName;

                            // 선택한 좌측 메뉴ID 설정 (session storage)
                            sessionStorage.setItem('selectedLeftMenuId', menuId);

                            break;
                        }
                        case 2: {

                            // 선택한 좌측 자식 메뉴명 설정
                            state.menu.selectedLeftChildMenuName = menuInfo.MenuName;

                            // 선택한 좌측 자식메뉴ID 설정 (session storage)
                            sessionStorage.setItem('selectedLeftChildMenuId', menuId);

                            break;
                        }
                        default:
                            break;
                    }

                    // 선택한 메뉴 활성여부 값 설정
                    state.menu.leftMenuItems.forEach(leftMenuInfo => {

                        if (menuLevel === 1) {

                            if (leftMenuInfo.MenuID === menuId) leftMenuInfo.isActive = !leftMenuInfo.isActive;
                            else leftMenuInfo.isActive = false;

                            !!leftMenuInfo.children && leftMenuInfo.children.forEach(childMenuInfo => childMenuInfo.isActive = false);
                        }
                        else {

                            if (!!leftMenuInfo.children) {

                                leftMenuInfo.children.forEach(childMenuInfo => {

                                    if (childMenuInfo.MenuID === menuId) childMenuInfo.isActive = true;
                                    else childMenuInfo.isActive = false;
                                });
                            }
                        }
                    });
                },
            },
        }

        // 메소드
        const methods = {

            /**
             * 로그아웃
             */
            logout: () => {

                // session storage clear
                sessionStorage.clear();

                // 로그아웃 토스트 메시지
                alertConfirmManager.alert.success('로그아웃 되었습니다.').then(() => {

                    // 로그인 화면으로 이동
                    location.href = '/login.html';
                });
            },

            /**
             * 홈화면으로 이동
             */
            goHome: () => {

                sessionStorage.removeItem('selectedTopMenuId');
                sessionStorage.removeItem('selectedLeftMenuId');
                sessionStorage.removeItem('selectedLeftChildMenuId');

                location.href = '/';
            },
        }

        onMounted(() => {

            // 저장되어있는 사용자정보 (session storage)
            const userInfo = JSON.parse(sessionStorage.getItem('UserInfo'));

            // 사용자정보 스토어에 설정
            store.dispatch('setUserInfo', userInfo).then(() => {

                // 메뉴목록 가져오기 및 가공
                menuManage.getUserByAuthMenuList(store.getters.getUserId).then(menuItems => {
                    
                    if (!menuItems.length) return;

                    // 루트로 접근 시 최초 보여줄 메뉴정보 설정 (session storage)
                    if (location.pathname === '/') {

                        sessionStorage.removeItem('selectedTopMenuId');
                        sessionStorage.removeItem('selectedLeftMenuId');
                        sessionStorage.removeItem('selectedLeftChildMenuId');

                        const topMenuItems = menuItems.filter(item => item.MenuLevel === 0);
                        const leftMenuItems = menuItems.filter(item => item.PrntMenuID === topMenuItems[0].MenuID && item.MenuLevel === 1);
                        const leftChildMenuItems = menuItems.filter(item => item.PrntMenuID === leftMenuItems[0].MenuID && item.MenuLevel === 2);

                        if (topMenuItems.length) sessionStorage.setItem('selectedTopMenuId', topMenuItems[0].MenuID);
                        if (leftMenuItems.length) sessionStorage.setItem('selectedLeftMenuId', leftMenuItems[0].MenuID);
                        if (leftChildMenuItems.length) sessionStorage.setItem('selectedLeftChildMenuId', leftChildMenuItems[0].MenuID);
                    }

                    // 저장되어있는 최상위 메뉴ID (session storage)
                    const selectedTopMenuId = sessionStorage.getItem('selectedTopMenuId');

                    // 저장되어있는 좌측 메뉴ID (session storage)
                    const selectedLeftMenuId = sessionStorage.getItem('selectedLeftMenuId');

                    // 저장되어있는 좌측 자식메뉴ID (session storage)
                    const selectedLeftChildMenuId = sessionStorage.getItem('selectedLeftChildMenuId');

                    // 최상위 메뉴 목록 filter
                    state.menu.topMenuItems = !!menuItems ? menuItems.filter(item => item.MenuLevel === 0) : null;

                    // 저장된 최상위 메뉴ID가 없을 시 첫번째 메뉴ID 저장 (session storage)
                    if (!selectedTopMenuId && !!state.menu.topMenuItems) sessionStorage.setItem('selectedTopMenuId', state.menu.topMenuItems[0].MenuID);

                    // 하위메뉴 탐색 재귀함수
                    const getChildMenuItems = (menuInfo) => {

                        // 대상메뉴에 해당하는 자식메뉴 목록
                        const childMenuItems = menuItems.filter(item => item.PrntMenuID === menuInfo.MenuID);

                        // 자식메뉴 목록 결과
                        const resultChildMenuItems = new Array();

                        childMenuItems.forEach(childMenuInfo => {

                            if (!!selectedLeftMenuId || !!selectedLeftChildMenuId) {

                                if (childMenuInfo.MenuID === selectedLeftMenuId || childMenuInfo.MenuID === selectedLeftChildMenuId) childMenuInfo.isActive = true;
                                else childMenuInfo.isActive = false;
                            }
                            else childMenuInfo.isActive = false;

                            childMenuInfo.children = getChildMenuItems(childMenuInfo);
                            resultChildMenuItems.push(childMenuInfo);
                        });

                        return resultChildMenuItems;
                    }

                    // 메뉴목록 가공 (트리형식으로)
                    state.menu.topMenuItems.forEach((topMenuInfo, index) => {

                        if (!!selectedTopMenuId) {

                            if (topMenuInfo.MenuID === selectedTopMenuId) topMenuInfo.isActive = true;
                            else topMenuInfo.isActive = false;
                        }
                        else {

                            if (index === 0) topMenuInfo.isActive = true;
                            else topMenuInfo.isActive = false;
                        }

                        topMenuInfo.children = getChildMenuItems(topMenuInfo);
                        state.menu.allMenuItems.push(topMenuInfo);
                    });

                    // 최초 좌측메뉴 및 네비게이션 설정
                    if (!!state.menu.allMenuItems) {

                        if (!!selectedTopMenuId) {

                            const selectedTopMenuInfo = state.menu.allMenuItems.find(item => item.MenuID === selectedTopMenuId);

                            state.menu.leftMenuItems = selectedTopMenuInfo.children;
                            state.menu.selectedTopMenuName = selectedTopMenuInfo.MenuName;

                            if (!!selectedLeftMenuId) {

                                const selectedLeftMenuInfo = selectedTopMenuInfo.children.find(item => item.MenuID === selectedLeftMenuId);
                                state.menu.selectedLeftMenuName = selectedLeftMenuInfo.MenuName;

                                if (!!selectedLeftChildMenuId) {

                                    const selectedLeftChildMenuInfo = selectedLeftMenuInfo.children.find(item => item.MenuID === selectedLeftChildMenuId);
                                    state.menu.selectedLeftChildMenuName = selectedLeftChildMenuInfo.MenuName;

                                    // 최초 페이지 라우팅
                                    if (router.currentRoute.value.path === '/') router.push({ path: selectedLeftChildMenuInfo.ProgramPath });
                                    else  router.push({ path: router.currentRoute.value.path, query: router.currentRoute.value.query });
                                }
                            }
                        }
                    }
                });
            });
        });

        return {
            store,
            state,
            eventHandler,
            methods,
            router,
        }
    },
}
</script>
<style scoped>
/* 메뉴 slide toggle 관련 스타일 재정의 시작 */
#gnb>ul>li>div>ul {
    display: block;
    width: 100%;
    background: #f8f9fa;
}

#gnb>ul>li>div>ul>li>a {
    display: block;
    font-size: 1em;
    color: #495057;
    padding: 18px 10px 18px 68px;
    position: relative;
}

#gnb>ul>li>div>ul>li>a:before {
    content: '\f068';
    font-size: 0.625em;
    font-weight: 300;
    font-family: 'Font Awesome 6 Pro';
    position: absolute;
    left: 35px;
    top: 50%;
    transform: translateY(-50%);
}

#gnb>ul>li.active>div>ul>li.active>a {
    background: #1d4ed8;
    color: #fff;
    font-weight: 700;
}

/* 메뉴 slide toggle 관련 스타일 재정의 끝 */
</style>